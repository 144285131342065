<template>
    <section class="section">
        <div class="columns">
            <div class="column is-four-fifths">
                <div class="content">
                    <h1 class="title">
                        Fredag, 16 Juni 2020
                    </h1>
                    <div class="container">
                        <div class="columns">
                            <div
                                v-for="(image, index) in images"
                                :key="index"
                                class="column"
                            >
                                <div class="box">
                                    <figure class="is-marginless image is-4by3">
                                        <img
                                            src="https://bulma.io/images/placeholders/1280x960.png"
                                            alt="Placeholder image"
                                        />
                                    </figure>
                                    <div class="content">
                                        <h5 class="title">
                                            Stamgatan 32, Älvsjö
                                        </h5>
                                        <p class="subtitle">
                                            Mer info här
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    name: 'List',
    data() {
        return {
            images: [
                {
                    name: 'First',
                    address: 'Test'
                },
                {
                    name: 'Second',
                    address: 'Test'
                },
                {
                    name: 'Third',
                    address: 'Test'
                },
                {
                    name: 'Forth',
                    address: 'Test'
                },
                {
                    name: 'Forth',
                    address: 'Test'
                }
            ]
        }
    }
}
</script>

<style scoped>
.is-four-fifths {
    margin: 0 auto;
}
</style>
